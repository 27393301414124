<template>
  <div>
    <base-header base-title="Payment Management"></base-header>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="'Payment Management'"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
              <!-- <base-button
                                :button-text="'Tambah Channels'"
                                :button-icon="'flaticon2-add-1'"
                                :button-name="'btn-now'"
                                :onButtonModalClicked="test"
                            ></base-button> -->
            </div>
          </div>
        </div>
        <div class="kt-portlet__body">
          <v-server-table
            @loaded="onLoaded(0)"
            @loading="onLoaded(1)"
            :columns="columns"
            :options="options"
            ref="table"
          >
            <div slot="action" slot-scope="{ row }">
                <router-link :to="{ name: `manajement-payment-id`,
                                    params: { id: row.userId } }">
                     <i class="la la-external-link" @click="goToChannels(row)"></i>
                </router-link>
              <!-- <button
                @click="goToChannels(row)"
                class="btn btn-sm btn-clean btn-icon btn-icon-md"
                title="Edit"
              >
                <i class="la la-external-link"></i>
              </button> -->
            </div>
            <div v-if="isLoading" slot="afterBody">
              <div class="m-auto p-5 d-flex flex-row h-100">
                <div
                  class="
                    kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary
                    my-auto
                  "
                ></div>
                <h5 class="my-auto pl-5">Loading ...</h5>
              </div>
            </div>
          </v-server-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from "./../../components/_base/BaseTitle";
import BaseHeader from "./../../components/_base/BaseHeader";
import { mapState, mapActions, mapMutations } from "vuex";
import { RepositoryFactory } from "../repositories/BaseRepositoryFactory";
const PaymentMethod = RepositoryFactory.get("PaymentMethod");
export default {
  components: {
    BaseTitle,
    BaseHeader,
  },
  data() {
    const vx = this;
    return {
      isLoading: false,
      columns: ["userId", "userName", "action"],
      options: {
        message: "No Result",
        texts: {
          filterBy: "Cari",
          loading: 'Loading...'
        },
        headings: {
          userId: "User Id",
          userName: "Name",
          action: "Actions",
        },
        filterable: false,
        debounce: 500,
        requestFunction: function (data) {
          let params = {};
          vx.options.texts.loading = 'Loading...'
          //   params.page = data.page-1;
          //   params.size = data.limit;
          if (!_.isEmpty(data.query)) {
            // params['templateName.contains'] = `${data.query.templateName}`;
          }
          if (data.orderBy) {
            // params.sort = `${vx.checkParams(data.orderBy)},${data.ascending == "1" ? 'desc': 'asc'}`
          }
          return PaymentMethod.fetchUser(params).catch(function (e) {
            vx.isLoading = false;
            toastr.error("Error Get Data");
            vx.options.texts.loading = 'Data tidak ditemukan'
          });
        },
        responseAdapter: (resp) => {
          return {
            data: resp.data ? resp.data : [],
            count: resp.headers["x-total-count"]
              ? resp.headers["x-total-count"]
              : resp.headers["Content-Length"]
              ? resp.headers["Content-Length"]
              : resp.data.length,
          };
        },
      },
    };
  },
  computed: {
    ...mapState("PaymentStore", {
      listChannels: (state) => state.listChannels,
      loadingAPI: (state) => state.loadingAPI,
    }),
  },
  methods: {
    goToChannels(row) {
      this.setDetailClient(row);
    //   this.$router.push({
    //     name: "manajement-payment-id",
    //     params: { id: row.userId },
    //   });
    },
    ...mapActions({
      getAllTransactions: "PaymentStore/GET_ALL_TRANSACTION_USER",
    }),
    ...mapMutations({
      setDetailClient: "PaymentStore/SET_DETAIL_TRANSAKSI_CLIENT",
    }),
    onLoaded: function (t) {
      t == 0 ? (this.isLoading = false) : (this.isLoading = true);
    },
  },
  mounted() {
    // this.getAllTransactions();
  },
};
</script>

<style>
</style>